import React, { useState, useEffect } from "react";
import styles from "./ProfileView.module.scss";
import { Typography } from "src/new-lib/Typography";
import { Button } from "src/new-lib/Button";
import { TextField } from "src/new-lib/TextField";
import { InfoCheckbox } from "src/new-lib/Checkbox";
import { Modal } from "src/new-lib/Modal";
import { Accordion } from "src/new-lib/Accordion";
import { TermsOfServices } from "src/new-lib/TermsOfServices";
import { useDeviceSize } from "src/new-lib/_utils";
import Loader from "react-loader-spinner";
import scss from "src/new-lib/_styles/index.module.scss";
import { FixMeLater } from "src/new-lib/_types";
import SkeletonLoader from "src/new-lib/Skeleton/SkeletonLoader";
import { CheckboxSkeleton } from "src/new-lib/Skeleton/CheckboxSkeleton";
import PhoneValidationSection from "./PhoneValidationSection";

const heroHeaderText =
  "Här hittar du allt som rör ditt konto. Hantera ditt konto och de samtycken som du lämnat till oss";

const removeAccountText =
  "Genom att klicka på knappen kommer du ta bort ditt användarkonto hos oss. Detta innebär att dina kontaktuppgifter raderas, och dina samtycken för återkallas. Denna åtgärd kan inte ångras. Du kan när som helst registrera dig på nytt genom att logga in med BankID och slutföra registreringen av ditt användarkonto.";

const confirmPhoneNumberTextLine1 =
  "När du uppdaterat ditt telefonnummer behöver du bekräfta det på nytt.";
const confirmPhoneNumberTextLine2 =
  "Tryck på knappen ”Skicka länk” här nedan, så får du ett sms med en länk - tryck på länken så är ditt nummer bekräftat";
type FieldProp = {
  value: string;
  errorText: string;
  onChange: (ev: React.FocusEvent) => void;
  disable: boolean;
};

type Props = {
  name: FixMeLater;
  phoneField: FieldProp;
  emailField: FieldProp;
  streetAddressField: FieldProp;
  zipCodeField: FieldProp;
  postAddressField: FieldProp;
  consents: [
    {
      key: number;
      disable: boolean;
      checked: boolean;
      title: string;
      paragraphs: string[];
      onChange: (ev: React.ChangeEvent) => void;
    }
  ];
  onSaveChangesButtonClick: (ev: React.MouseEvent) => void;
  showHasSavedText: boolean;
  resetHasSavedText: () => void;
  onDeleteAccountClick: () => void;
  termsOfService: FixMeLater;
  loading: boolean;
  isPhoneNumberValidated: boolean;
  onSendPhoneNumberValidationLink: () => void;
  openSmsSentPopUp: boolean;
  handleClosePhoneValidationPopUp: () => void;
};

export const ProfileView = ({
  name,
  phoneField,
  emailField,
  streetAddressField,
  zipCodeField,
  postAddressField,
  consents,
  onSaveChangesButtonClick,
  showHasSavedText,
  resetHasSavedText,
  onDeleteAccountClick,
  termsOfService,
  loading,
  isPhoneNumberValidated,
  onSendPhoneNumberValidationLink,
  openSmsSentPopUp,
  handleClosePhoneValidationPopUp,
}: Props) => {
  const { isMobile } = useDeviceSize();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenModal = () => setModalIsOpen(true);
  const handleCloseModal = () => setModalIsOpen(false);

  const [hasEditedProfile, setHasEditedProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePhoneField = (ev: React.FocusEvent) => phoneField.onChange(ev);
  const handleEmailField = (ev: React.FocusEvent) => emailField.onChange(ev);
  const handleStreetAddressField = (ev: React.FocusEvent) =>
    streetAddressField.onChange(ev);
  const handleZipCodeField = (ev: React.FocusEvent) =>
    zipCodeField.onChange(ev);
  const handlePostAddressField = (ev: React.FocusEvent) =>
    postAddressField.onChange(ev);

  const handleChange = (ev: React.ChangeEvent) => {
    ev.preventDefault();
    setHasEditedProfile(true);
    resetHasSavedText();
  };

  const handleCheckChange = (
    ev: React.ChangeEvent,
    _onChange: React.ChangeEventHandler
  ) => {
    ev.preventDefault();
    setHasEditedProfile(true);
    _onChange(ev);
    resetHasSavedText();
  };

  const handleSaveChanges = (ev: React.MouseEvent) => {
    ev.preventDefault();
    onSaveChangesButtonClick(ev);

    if (
      !phoneField.errorText &&
      !emailField.errorText &&
      !streetAddressField.errorText &&
      !zipCodeField.errorText &&
      !postAddressField.errorText
    ) {
      setIsLoading(true);
      setHasEditedProfile(false);
    }
  };

  useEffect(() => {
    if (showHasSavedText) {
      setIsLoading(false);
      setHasEditedProfile(false);
    }
  }, [showHasSavedText]);

  const renderTextFields = () => {
    return (
      <>
        {name && <TextField value={name} title="Namn" isDisabled />}
        <TextField
          value={phoneField.value}
          title="Mobilnummer"
          errorMessage={phoneField.errorText}
          isError={!!phoneField.errorText}
          onBlur={handlePhoneField}
          onChange={handleChange}
        />
        <TextField
          value={emailField.value}
          title="E-post"
          errorMessage={emailField.errorText}
          isError={!!emailField.errorText}
          onBlur={handleEmailField}
          onChange={handleChange}
        />
        <TextField
          value={streetAddressField.value}
          title="Gatuadress"
          errorMessage={streetAddressField.errorText}
          isError={!!streetAddressField.errorText}
          onBlur={handleStreetAddressField}
          onChange={handleChange}
        />
        <TextField
          value={zipCodeField.value}
          title="Postnummer"
          errorMessage={zipCodeField.errorText}
          isError={!!zipCodeField.errorText}
          onBlur={handleZipCodeField}
          onChange={handleChange}
        />
        <TextField
          value={postAddressField.value}
          title="Postort"
          errorMessage={postAddressField.errorText}
          isError={!!postAddressField.errorText}
          onBlur={handlePostAddressField}
          onChange={handleChange}
        />
      </>
    );
  };

  const renderSkeleton = () => {
    return (
      <div style={{ marginTop: "24px" }}>
        <div
          style={isMobile ? { marginBottom: "32px" } : { marginBottom: "48px" }}
        >
          <SkeletonLoader />
        </div>
        <div
          style={
            isMobile
              ? { marginBottom: "32px", width: "204px" }
              : { marginBottom: "48px", width: "204px" }
          }
        >
          <SkeletonLoader />
        </div>
        <div
          style={isMobile ? { marginBottom: "32px" } : { marginBottom: "48px" }}
        >
          <SkeletonLoader />
        </div>
        <div
          style={
            isMobile
              ? { marginBottom: "32px", width: "204px" }
              : { marginBottom: "48px", width: "204px" }
          }
        >
          <SkeletonLoader />
        </div>
        <div
          style={isMobile ? { marginBottom: "32px" } : { marginBottom: "48px" }}
        >
          <SkeletonLoader />
        </div>
        <div
          style={
            isMobile
              ? { marginBottom: "32px", width: "204px" }
              : { marginBottom: "48px", width: "204px" }
          }
        >
          <SkeletonLoader />
        </div>
      </div>
    );
  };

  const Consents = (): React.ReactNode[] =>
    consents.map((con, index) => {
      return (
        <>
          {loading || isLoading ? (
            <CheckboxSkeleton />
          ) : (
            <InfoCheckbox
              key={index}
              defaultChecked={con.checked}
              label={con.title}
              modalHeading={con.title}
              modalText={con.paragraphs}
              buttonCloseLabel="Stäng"
              modalSize="md"
              modalTextAlign="left"
              onChange={(ev) => handleCheckChange(ev, con.onChange)}
            />
          )}
        </>
      );
    });

  const [selectedAccordion, setSelectedAccordion] = useState<string | null>(
    null
  );
  const handleClick = (id: string) =>
    setSelectedAccordion(selectedAccordion === id ? null : id);

  return (
    <>
      <section className={styles["hero-header"]}>
        <div className={styles["hero-content"]}>
          <Typography
            tag="h1"
            align={isMobile ? "center" : null}
            margin={{ b: isMobile ? 16 : 24 }}
          >
            Din profil
          </Typography>
          <Typography tag="p" align={isMobile ? "center" : null} margin={{}}>
            {heroHeaderText}
          </Typography>
        </div>
      </section>
      
      <div className={styles.body}>
        <div className={styles["content-container"]}>
        { !isPhoneNumberValidated && <PhoneValidationSection 
        isMobile={isMobile} 
        line1={confirmPhoneNumberTextLine1} 
        line2={confirmPhoneNumberTextLine2} 
        clickEvent={onSendPhoneNumberValidationLink} /> }

          <section className={styles["edit-information-card"]}>
            <div className={styles["contact-details-container"]}>
              <Typography tag="h2" margin={{ b: 8 }}>
                Kontaktuppgifter
              </Typography>
              {loading || isLoading ? renderSkeleton() : renderTextFields()}
            </div>
            <div className={styles["consents-container"]}>
              <div className={styles.consents}>
                <Typography tag="h2" margin={{ b: isMobile ? 4 : 12 }}>
                  Samtycken
                </Typography>
                {Consents()}
              </div>
              <div className={styles.btn}>
                <Button
                  expand
                  variant="primary"
                  onMouseUp={handleSaveChanges}
                  disabled={!hasEditedProfile}
                >
                  Spara ändringar
                </Button>
                <div className={styles["has-saved-changes-text-container"]}>
                  {isLoading && !showHasSavedText && (
                    <Loader
                      type="Oval"
                      color={scss.primaryNavy}
                      width={25}
                      height={25}
                    />
                  )}
                  {showHasSavedText && (
                    <Typography tag="p" margin={{}}>
                      Dina ändringar har sparats
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className={styles["remove-account-card"]}>
            <Typography tag="h2" margin={{}}>
              Ta bort konto
            </Typography>
            <Typography tag="p" margin={{ t: isMobile ? 16 : 24, b: 24 }}>
              {removeAccountText}
            </Typography>
            <div className={styles.btn}>
              <Button
                expand
                variant="secondary-light"
                onClick={handleOpenModal}
              >
                ta bort konto
              </Button>
            </div>
          </section>

          <Accordion
            id="1"
            selectedAccordion={selectedAccordion}
            onClick={handleClick}
            expand
            slim
            headerContent={
              <Typography tag="h3" margin={{}}>
                Datahantering och villkor
              </Typography>
            }
          >
            <TermsOfServices
              termsOfService={termsOfService}
              overrides={{
                h2: { component: Typography, props: { tag: "h4" } },
                h3: { component: Typography, props: { tag: "p" } },
                p: { component: Typography, props: { tag: "p" } },
                ul: { props: { className: styles.ul + " " + Typography } },
                li: { props: { className: styles.li } },
              }}
            />
          </Accordion>
        </div>

        <Modal
          iconName="bin"
          title="Bekräfta"
          text="Är du säker på att du vill ta bort ditt konto?"
          isOpen={modalIsOpen}
          closeButton={{
            variant: isMobile ? "secondary-light" : "text",
            text: "avbryt",
          }}
          handleClose={handleCloseModal}
        >
          <Button
            loading={loading}
            disabled={loading}
            variant="danger"
            onClick={onDeleteAccountClick}
          >
            ta bort konto
          </Button>
        </Modal>

        <Modal
          iconName="mobile"
          title="Du har fått SMS!"
          text="Tryck på länken så är ditt nummer bekräftat."
          isOpen={openSmsSentPopUp}
          closeButton={{
            variant: isMobile ? "primary" : "text",
            text: "Stäng",
          }}
          handleClose={handleClosePhoneValidationPopUp}
        ></Modal>
      </div>
    </>
  );
};
