import React, { useState, useEffect, useRef, ReactNode } from 'react'
import PropTypes from 'prop-types'
import styles from './Menu.module.scss'
import { Icon, IconNames } from '../Icon'
import { useDeviceSize, useDisableScroll } from '../_utils'
import { LinkTo } from '../LinkTo'
import classNames from 'classnames'
import { paths } from 'src/utils/urlPaths'
import { FixMeLater } from '../_types'

type Section = {
  display: string
  icon?: IconNames
  iconSize?: string
  to?: string
  onClick?: () => void
  track?: object
}

type Props = {
  isOpen: boolean
  onClose: () => void
  onSignOut: () => void
  children: ReactNode
}

export const Menu = ({ isOpen, onClose, onSignOut, children }: Props) => {
  const { isMobile } = useDeviceSize()
  useDisableScroll(isMobile && isOpen)

  const [closeMenu, setCloseMenu] = useState(false)

  const desktopMenuElement = useRef<HTMLDivElement>(null)
  const desktopAnimationRef = useRef<HTMLDivElement>(null)
  const arrowElement = useRef<HTMLDivElement>(null)
  const mobileMenuElement = useRef<HTMLDivElement>(null)

  const sections: Section[] = [
    {
      display: 'Sök vård och se ärenden',
      icon: 'hospital-house',
      to: paths['switchboard'],
      track: {
        name: 'seek_care_cta',
        category: 'home',
        action: 'to_home',
        pageContext: 'top_nav'
      }
    },
    {
      display: 'Din profil',
      to: paths['my profile'],
      iconSize: 'lg',
      icon: 'singel-user-neutral',
      track: {
        category: 'my_profile',
        action: 'to_my_profile',
        pageContext: 'top_nav_dropdown'
      }
    },
    {
      display: 'Din förvalda mottagning',
      to: paths['chat > settings > selected'],
      icon: 'stethoscope'
    },
    {
      display: 'Lista dig hos Capio',
      to: paths['listing > select'],
      icon: 'completed',
      track: {
        name: 'listing_dropdown_link',
        category: 'listing',
        action: 'to_listing',
        pageContext: 'top_nav_dropdown'
      }
    },
    {
      display: 'Hitta mottagning',
      to: paths['care units'],
      icon: 'search',
      track: {
        name: 'topbar_menu_link',
        category: 'digital_care',
        action: 'to_digital_care',
        pageContext: 'top_nav_dropdown'
      }
    },
    {
      display: 'Vanliga frågor om Vård Online',
      to: paths['faq'],
      icon: 'question',
      track: {
        name: 'topbar_menu_link',
        category: 'awareness',
        action: 'to_faq',
        pageContext: 'top_nav_dropdown'
      }
    },
    {
      display: 'Logga ut',
      to: paths['logout'],
      iconSize: 'lg',
      icon: 'logout',
      onClick: onSignOut
    }
  ]

  useEffect(() => {
    if (isOpen) document.addEventListener('click', handleBackgroundClick)
  }, [isOpen])

  const mobileClasses = classNames({
    [styles['mobile-box']]: true,
    [styles['slide-in']]: isOpen,
    [styles['slide-out']]: closeMenu
  })

  const desktopClasses = classNames({
    [styles['desktop-box']]: true,
    [styles['fade-in']]: isOpen,
    [styles['fade-out']]: closeMenu
  })

  const handleClose = () => {
    setCloseMenu(true)

    const handleAnimation = () => {
      document.removeEventListener('click', handleBackgroundClick)
      setCloseMenu(false)
      onClose()
    }

    if (desktopAnimationRef.current) {
      desktopAnimationRef.current.addEventListener('animationend', () =>
        handleAnimation()
      )
      desktopAnimationRef.current.removeEventListener('animationend', () =>
        handleAnimation()
      )
    } else if (mobileMenuElement.current) {
      mobileMenuElement.current.addEventListener('animationend', () =>
        handleAnimation()
      )
      mobileMenuElement.current.removeEventListener('animationend', () =>
        handleAnimation()
      )
    }
  }

  const clickedOutsideElement = (
    element: React.MutableRefObject<HTMLDivElement>,
    event: { target: FixMeLater }
  ): FixMeLater => {
    if (!element.current) return false

    if (!element.current.contains(event.target)) return true
    else return false
  }

  const handleBackgroundClick = (event: {
    target: FixMeLater
    preventDefault: FixMeLater
  }): FixMeLater => {
    if (
      clickedOutsideElement(desktopMenuElement, event) &&
      clickedOutsideElement(arrowElement, event)
    ) {
      event.preventDefault()
      handleClose()
    } else if (clickedOutsideElement(mobileMenuElement, event)) {
      event.preventDefault()
      handleClose()
    }
  }

  type DesktopProps = {
    sections: Section[]
    open: boolean
    classes: string
  }

  const Desktop = ({ sections, open, classes }: DesktopProps) => {
    return (
      <>
        <div className={styles['popover-container']}>
          {children}
          <div className={styles['popover-content-desktop']}>
            {open && (
              <div ref={desktopAnimationRef} className={classes}>
                <div ref={arrowElement} className={styles.arrow} />
                <div ref={desktopMenuElement} className={styles.desktop}>
                  <Section data={sections} />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  }

  type MobileProps = {
    onClose: () => void
    sections: Section[]
    open: boolean
    classes: string
  }

  const Mobile = ({ onClose, sections, open, classes }: MobileProps) => {
    return (
      <>
        {children}
        <div
          ref={mobileMenuElement}
          style={{ display: open ? 'block' : 'none' }}
          className={classes}
        >
          <div className={styles.header}>
            <button onClick={onClose} className={styles['close-button']}>
              <Icon color="primary" size="lg" name="close" />
            </button>
          </div>
          <div className={styles.padding}>
            <Section data={sections} />
          </div>
        </div>
      </>
    )
  }

  return isMobile ? (
    <Mobile
      onClose={handleClose}
      sections={sections}
      open={isOpen}
      classes={mobileClasses}
    />
  ) : (
    <Desktop sections={sections} open={isOpen} classes={desktopClasses} />
  )
}

type SectionProps = {
  data: Section[]
}

// Menus list items
const Section = ({ data }: SectionProps) => {
  return (
    <nav className={styles.items}>
      {data.map((d, i) => (
        <ListItems key={i.toString()} data={d} track={d.track} />
      ))}
    </nav>
  )
}

type ListItemsProps = {
  data: Section
  track?: FixMeLater
}

const ListItems = ({ data, track }: ListItemsProps) => {
  const [isPressed, setIsPressed] = useState<boolean>(false)

  const sectionClassNames = `${styles.section} ${isPressed &&
    styles['section-pressed']}`

  const handleTouchStart = () => setIsPressed(true)
  const handleTouchEnd = () => setIsPressed(false)

  const [isEnterMouse, setIsEnterMouse] = useState<boolean>(false)

  const handleBackground = (state: boolean) => {
    setIsEnterMouse(state)
  }

  return window.location.pathname === data.to ? (
    <button
      onClick={() => null}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      className={isEnterMouse ? sectionClassNames : styles.button}
      onMouseEnter={() => handleBackground(true)}
      onMouseLeave={() => handleBackground(false)}
    >
      <div className={styles.circle}>
        <span className={styles['icon-default']}>
          <Icon
            color="primary"
            size={data.iconSize ? data.iconSize : 'md'}
            name={data.icon}
          />
        </span>
        <span className={styles['icon-hover']}>
          <Icon
            color="secondary"
            size={data.iconSize ? data.iconSize : 'md'}
            name={data.icon}
          />
        </span>
      </div>
      <span className={styles.text}>{data.display}</span>
    </button>
  ) : (
    <LinkTo
      expand
      className={sectionClassNames}
      to={data.to}
      // to={`${data.to}`}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      dataTrack={track}
    >
      <div className={styles.circle}>
        <span className={styles['icon-default']}>
          <Icon
            color="primary"
            size={data.iconSize ? data.iconSize : 'md'}
            name={data.icon}
          />
        </span>
        <span className={styles['icon-hover']}>
          <Icon
            color="secondary"
            size={data.iconSize ? data.iconSize : 'md'}
            name={data.icon}
          />
        </span>
      </div>
      <span className={styles.text}>{data.display}</span>
    </LinkTo>
  )
}

Menu.propTypes = {
  // sections: PropTypes.object.isRequired,
  onSignOut: PropTypes.func,
  buttonText: PropTypes.string
}
