import React, { Component } from 'react'
import { connect } from 'react-redux'
import config from '../../api/config'
import queryString from 'query-string'
import { FlowScreen } from './FlowScreen'
import { paths } from '../../utils/urlPaths'
import { LoadingScreen } from '../../new-lib/LoadingScreen/LoadingScreen'
import { FixMeLater } from 'src/new-lib/_types'

type Props = {
  app: FixMeLater
  centers: FixMeLater
  location: FixMeLater
  history: FixMeLater
}

type States = {
  fullCapioURL: string
  logoutIsInitiated: boolean
}

class Flow extends Component<Props, States> {
  constructor (props: Props) {
    super(props)
    this.state = {
      fullCapioURL: '',
      logoutIsInitiated: false
    }
  }

  componentDidMount () {
    const urlQueryParams = queryString.parse(this.props.location.search)
    const extractSelectedHCU = this.props.centers.find(
      (hcu: FixMeLater) => hcu.idExt === urlQueryParams.hid
    )
    if (
      urlQueryParams.hid &&
      extractSelectedHCU &&
      extractSelectedHCU.isDigitalProviderActive
    ) {
      const targetUrl = `${config.capioGoOrigin}${extractSelectedHCU.digitalProviderRelativeUri}/external-apps/login-success?isIframe=true`
      this.setState({ fullCapioURL: targetUrl })
    } else {
      this.setState({ fullCapioURL: config.capioGo })
    }
    window.addEventListener('message', this.msgHandler, false)
  }

  msgHandler = (e: MessageEvent) => {
    if (e && e.data) {
      if (e.data === 'flow:logout') {
        this.setState({ logoutIsInitiated: true })
      }
      if (e.data === 'flow:show-unit-list') {
        this.props.history.push(paths["care units"])
      }
    }
  }

  logoutLoadHandler = () => {
    if (this.state.logoutIsInitiated) {
      this.props.history.push("/signout")
    }
  }

  render () {
    return <FlowScreen targetSrc={this.state.fullCapioURL} onLoad={this.logoutLoadHandler} />
  }
}

export const FlowPage = connect((state: FixMeLater) => {
  return {
    app: state.app,
    centers: state.healthCenter.digitalCenters
  }
})(Flow)
