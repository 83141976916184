import type { FAQContent, FlowOnboardingStartContent, HomeContent, TopbarContent } from "src/api/services/ContentAPIModel"
import type { AxiosError } from "axios"

export type ContentState = {
    faq: { isLoading: boolean, data?: FAQContent, error?: AxiosError }
    home: { isLoading: boolean, data?: HomeContent, error?: AxiosError },
    flowOnboardingStart: { isLoading: boolean, data?: FlowOnboardingStartContent, error?: AxiosError }
    topbar: { isLoading: boolean, data?: TopbarContent, error?: AxiosError }
}

export const initialState: ContentState = {
    faq: { isLoading: false },
    home: { isLoading: false },
    flowOnboardingStart: { isLoading: false },
    topbar: { isLoading: false }
}