import NetworkError from '../NetworkError';
import config from '../config';

export class ConsentAPI {
  constructor(http) {
    this._http = http;
    this._endpoint = `${config.base}/api/v1/consent/people`;
    this._endpoint_new = `${config.base}/api/v1/consent/b2c/customers`;
    this._endpoint_new_v2 = `${config.base}/api/v2/consent/b2c/customers`;
    this._endpointUsers = `${config.base}/api/v1/consent/b2c/customers`;
    this._endpointConsentTypes = `${config.base}/api/v2/consent/b2c/customers/consent-types`;
  }

  async createConsent(socialSecurityNumber, token) {
    const res = await this._http.post(
      this._endpoint,
      {
        socialSecurityNumber
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.data);
    }

    return res.data;
  }

  async getAllConsents(token) {
    const res = await this._http.get(
      this._endpointConsentTypes,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.data);
    }

    return res.data;
  }

  async getAllUserConsents(token, personId) {
    const res = await this._http.get(
        `${this._endpoint_new_v2}/active-consents`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
    );

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.data);
    }

    return res.data;
  }

  async saveAllUserConsents(token, personId, consentTypeId) {
    const res = await this._http.post(
        `${this._endpoint}/${personId}/active-consents`,
        {
          "id": consentTypeId
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
    );

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.data);
    }
    return res.data;
  }

  async deleteUserConsents(token, personId, id) {
    const res = await this._http.delete(
        `${this._endpoint}/${personId}/active-consents/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
    );

    if (res.status !== 204) {
      throw new NetworkError(res.status, res.data);
    }
    return res.data;
  }

  async updateContactInformation(personId, payload, token) {
    const url = `${this._endpoint_new}/contact-info`;
    const res = await this._http.put(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }

  async createUser(payload, token) {
    const url = `${this._endpointUsers}`;
    const res = await this._http.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }

  async contactInformation(personId, token) {
    const url = `${this._endpoint_new}/contact-info`;
    const res = await this._http.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }

  async getSparInformation(token) {
    const url = `${this._endpoint_new}/spar-info`;
    const res = await this._http.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }

  async isUserRegistered(token) {
    const url = `${this._endpoint_new}/exist`;
    const res = await this._http.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }

  async deleteUserInfo(token) {
    const url = `${this._endpoint_new}`;
    const res = await this._http.delete(url, {
      headers: { Authorization: `Bearer ${token}` }
    });

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }

  async saveUserConsents(payload, token) {
    const url = `${this._endpoint_new}/active-consents`;
    const res = await this._http.put(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }

  async updateAssociatedCareGivers(token, payload) {
    const url = `${this._endpoint_new}/associated-care-givers`;
    const res = await this._http.put(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (res.status !== 200) {
      throw new NetworkError(res.status, res.statusText, res.data);
    }

    return res.data;
  }
}
