import { useState, useRef, useEffect } from 'react'
import CapioLogo from '../../assets/logos/capio-blue-logo.svg'
import HamburgerMenu from '../../assets/sl-icons/hamburger-menu.svg'
import styles from './Header.module.scss'
import { LinkTo } from '../LinkTo'
import { Typography } from '../Typography'
import { Menu } from '../Menu'
import { Icon } from 'src/new-lib/Icon'
import { useDeviceSize } from '../../new-lib/_utils'
import { Button, IconButton } from '../Button'
import classNames from 'classnames'
import { createTryGetContentValueFn } from 'src/api/services/ContentAPI'
import { RootState } from 'src/store/Model'

export type HeaderVariants = 'default' | 'chat' | 'fast-listing' | 'none'

type Props = {
  isLoggedIn: boolean
  onSignOut?: () => void
  menuButtonText?: string
  variant?: HeaderVariants,
  content?: RootState["content"]["topbar"]
}

export const Header = ({
  isLoggedIn,
  onSignOut,
  menuButtonText,
  variant = 'default',
  content
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpenMenu = () => setIsOpen(true)
  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const header = headerRef.current
    if (header) {
      const handleScroll = () => {
        const isSticky = window.pageYOffset > header.offsetTop
        if (isSticky) {
          header.classList.add(styles['sticky-header'])
        } else {
          header.classList.remove(styles['sticky-header'])
        }
      }
      handleScroll()
      window.addEventListener('scroll', handleScroll)
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [headerRef])

  const { isDesktop, isMobile } = useDeviceSize()

  const headerContainer = classNames({
    [styles['header-container']]: variant !== 'chat',
    [styles['chat-header-container']]: variant === 'chat'
  })

  const inboxBtnContainer = classNames({
    [styles["inbox-btn-desktop"]]: isDesktop,
    [styles["inbox-btn-mobile"]]: isMobile
  })
  console.log(content, "<-------", "apaboll")
  const tryGetValue = createTryGetContentValueFn(content)

  const LoggedInVariant = () => {
    return (
      <div className={styles['logged-in-content']}>
        <Menu
          onSignOut={onSignOut}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className={styles['button-container']}>
            <Button
              variant="primary"
              iconLeft="singel-user-neutral"
              iconSize="lg"
              childMargin={{ l: 6, t: 2 }}
              onClick={handleOpenMenu}
              margin={{ h: 56 }}
            >
              {menuButtonText || 'Meny'}
            </Button>
          </div>
          <img
            className={styles.hamburger}
            src={HamburgerMenu}
            onClick={handleOpenMenu}
            alt="hamburger menu icon"
          />
        </Menu>
        <div className={inboxBtnContainer}>
          <Button to={{ name: "flow > capio go", params: ["hid=e2ncwz9fl9"]}} variant="tertiary" iconLeft="inbox">{ tryGetValue(c => c.inboxButton.text) }</Button>
        </div>
        {isDesktop && (
          <LinkTo
            to="home"
            animation
            dataTrack={{
              name: 'home_cta',
              category: 'home',
              action: 'to_home'
            }}
          >
            <Typography tag="span" weight="700">
              Hem
            </Typography>
          </LinkTo>
        )}
      </div>
    )
  }

  const ChatVariant = () => {
    return (
      <div className={styles['chat-variant']}>
        <Button
          small={isMobile}
          to="listing > select"
          track={{
            name: 'listing_cta',
            category: 'listing',
            action: 'to_listing'
          }}
        >
          Lista dig
        </Button>
        {isDesktop ? (
          <Button
            variant="secondary-light"
            to="home"
            iconRight="close"
            childMargin={{ r: 6 }}
          >
            Lämna
          </Button>
        ) : (
          <IconButton variant="secondary" to="home" />
        )}
      </div>
    )
  }

  const FastListingVariant = () => (
    <div className={styles['chat-variant']}>
      <Button to="logout">logga ut</Button>
    </div>
  )

  const renderVariant = () => {
    if (isLoggedIn && variant === 'default') return <LoggedInVariant />
    else if (isLoggedIn && variant === 'fast-listing')
      return <FastListingVariant />
    else if (variant === 'chat') return <ChatVariant />
    else if (!isLoggedIn) return null
    return <LoggedInVariant />
  }

  const renderBackLink = () => {
    if (variant === 'chat') {
      return null
    }
    return (
      <div className={styles['back-link-container']}>
        <LinkTo
          animation
          className={styles['back-to-link']}
          to="https://capio.se"
        >
          <Icon name="nav-arrow-left" margin={{ b: 2 }} />
          <Typography tag="em" margin={{}}>
            Tillbaka till capio.se
          </Typography>
        </LinkTo>
      </div>
    )
  }

  const LinkToMargin = isDesktop ? { v: 40, h: 56 } : { v: 20 }

  return variant === 'none' ? null : (
    <>
      {renderBackLink()}
      <div className={headerContainer} ref={headerRef}>
        <nav className={styles.header}>
          <LinkTo
            margin={LinkToMargin}
            to="home"
            dataTrack={{
              name: 'home_cta',
              category: 'home',
              action: 'to_home'
            }}
          >
            <img className={styles['logo-image']} src={CapioLogo} />
          </LinkTo>
          {renderVariant()}
        </nav>
      </div>
    </>
  )
}
