/** 
 * @typedef {import("../../../api/services/TermsOfServiceAPI").TermsOfService} TermsOfService
 * @typedef {{
 *   latestTermsOfService: TermsOfService | "loading" | "none" | "error" | "uninitialized",
 *   termsOfServiceToAccept: TermsOfService | "loading" | "none" | "error" | "uninitialized",
 *   tosAcceptance: "loading" | "accepted" | "error" | "uninitialized",
 * }} TermsOfServiceState
 */

/**
 * @type {TermsOfServiceState}
 */
export const initialState = {
  latestTermsOfService: "uninitialized",
  termsOfServiceToAccept: "uninitialized",
  tosAcceptance: "uninitialized",
};
  