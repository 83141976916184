import { Component } from 'react'
import { thunks as featureThunks } from './store/reducers/featureManager'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { PrivateRoute } from './lib'
import {
  RegistrationRoutes,
  ProfileRoutes,
  FaqRoutes,
  ListingRoutes,
  FastListingRoutes,
  HealthcareUnitsRoutes,
  SignCallbackPageBeta,
  SwitchboardPage,
  FlowPage,
  HomePage
} from './pages'
import { actions } from './store/reducers/app'
import { actions as contentActions } from './store/reducers/content'
import config from './api/config'
import { paths } from './utils/urlPaths'
import { ErrorPage } from './pages/ErrorPage/ErrorPage'
import { ScrollToTop } from './lib/ScrollToTop'
import { LoadingScreen } from './new-lib/LoadingScreen/LoadingScreen'
import { LogoutPage } from './pages/Logout'
import { NewsletterUnsubscribePage } from './pages/Newsletter'
import { UpdatedTermsOfServiceModal } from './lib/TermsOfService/'
import { LoginPage, CallbackPage } from './pages/Login'
import { VerifyCodePage } from './pages/PhoneValidation/VerifyCode'
import { CookiebotProxyPage } from './pages/CookiebotProxy'
import { ErrorBoundary } from 'react-error-boundary'
import { trackGlobalError } from './new-lib/_utils/analytics'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contactInfoLoaded: false,
      centersLoaded: false,
      centersRegions: false,
      loaded: false,
      triggerError: false
    }

    this.props.dispatch(actions.fetchMinimumUserState()).then(() => {
      this.setState({ loaded: true })
      if (this.state.loaded) {
        this.setState({ triggerError: true }) // This will trigger an error
      }
    })
  }

  componentDidMount() {
    this.props.dispatch(featureThunks.tryFetchFeatureConfig())
    this.props.dispatch(featureThunks.tryInitializeReloadInterval())
    this.props.dispatch(contentActions.fetchTopbar())
  }

  renderApp = ({ userLoggedIn, userIsRegistered }) => {
    console.log('Capio Online App')
    return (
      <div style={{ flex: 1, display: 'flex' }}>
        <Switch>
          <PrivateRoute
            search={this.props.location}
            isRegistered={userIsRegistered}
            isAuthenticated={userLoggedIn}
            path={paths['home']}
            errorOccured={this.props.app.errorOccured}
            component={HomePage}
          />
          //TODO: Try remove the duplication of Routes pointing to LoginPage
          <Route path={paths['index']} exact component={LoginPage} />
          <Route path="/noapp" exact component={LoginPage} />
          //TODO: Duplicate Route of LoginPage
          <Route path={paths['login']} render={() => <LoginPage />} />
          <PrivateRoute
            path={paths['flow > capio go']}
            exact
            component={FlowPage}
            isAuthenticated={userLoggedIn}
            unauthorizedPath={paths['login']}
            isRegistered={userIsRegistered}
          />
          <Route path={paths['callback']} component={CallbackPage} />
          <PrivateRoute
            path={'/registration'}
            component={RegistrationRoutes}
            isAuthenticated={userLoggedIn}
            unauthorizedPath={paths['login']}
          />
          <Route
            path={paths['login > callback']}
            component={SignCallbackPageBeta}
          />
          <Route path={paths['logout confirmation']} component={LogoutPage} />
          <Route
            path={paths['logout']}
            render={() => {
              const postLogoutURIRedirect = encodeURI(
                `${config.redirectBase}/logout`
              )

              const idToken = localStorage.getItem('idToken')
              const endSessionURI = `${config.baseAuth}/connect/endsession?id_token_hint=${idToken}&post_logout_redirect_uri=${postLogoutURIRedirect}`
              localStorage.clear()

              window.location = endSessionURI
              return <></>
            }}
          />
          <Route
            path={paths['unsubscribe (type, token)']}
            component={NewsletterUnsubscribePage}
          />
          <Route
            path={paths['cookiebot proxy']}
            component={CookiebotProxyPage}
          />
          <PrivateRoute
            search={this.props.location}
            isAuthenticated={userLoggedIn}
            path="/listing"
            component={ListingRoutes}
          />
          <PrivateRoute
            search={this.props.location}
            isAuthenticated={userLoggedIn}
            path={paths['switchboard']}
            isRegistered={userIsRegistered}
            component={() => <SwitchboardPage loadedApp={this.state.loaded} />}
          />
          <PrivateRoute
            search={this.props.location}
            isAuthenticated={userLoggedIn}
            path="/fastlisting"
            component={() => <FastListingRoutes />}
          />
          <PrivateRoute
            isAuthenticated={userLoggedIn}
            path={paths['faq']}
            component={() => <FaqRoutes />}
          />
          <PrivateRoute
            isAuthenticated={userLoggedIn}
            isRegistered={userIsRegistered}
            path={'/settings'}
            component={ProfileRoutes}
          />
          <PrivateRoute
            isAuthenticated={userLoggedIn}
            isRegistered={userIsRegistered}
            path={paths['care units']}
            component={() => <HealthcareUnitsRoutes />}
          />
          <Route path={paths['vericode']} component={VerifyCodePage} />
          <Route path={paths['error']} component={() => <ErrorPage />} />
        </Switch>
        <UpdatedTermsOfServiceModal />
      </div>
    )
  }

  render() {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorPage}
        onError={(error, errorInfo) => trackGlobalError(error, errorInfo)}
      >
        <Router>
          <>
            {!this.state.loaded && <LoadingScreen />}
            {this.state.loaded && (
              <ScrollToTop>{this.renderApp(this.props.app)}</ScrollToTop>
            )}
          </>
        </Router>
      </ErrorBoundary>
    )
  }
}

export default connect((state) => {
  return { app: state.app }
})(App)
