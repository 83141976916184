import {generateAuthConfig } from './userManagerConfigs';
import queryString from 'query-string';
import config from '../../api/config';


export const signIn = (params) => {
    const queryParams = queryString.stringify(generateAuthConfig(params));
    const authRedirect = `${config.baseAuth}/connect/authorize?${queryParams}`;
    window.location.replace(authRedirect);
};
