import NetworkError from "../NetworkError";
import config from "../config";

/**
 * @typedef {{
 *  id: string,
 *  content: string
 * }} TermsOfService
 */

export class TermsOfServicesAPI {
  constructor(http) {
    this._http = http;

    const serviceBase = `${config.base}/api/v1/terms-of-services`

    this._endpointLatest = `${serviceBase}/latest`;
    this._endpointAccept = (termsId) => `${serviceBase}/${termsId}/accept`
    this._endpointToAccept = `${serviceBase}/to-accept`;
  }

  /**
   * @returns {Promise<TermsOfService | null>}
   */
  async getLatestTos() {
    const res = await this._http.get(this._endpointLatest);

    switch (res.status) {
      case 200:
        return res.data;
      default:
        return null;
    }
  }

   /**
    * @param {string} token - authorization token
    * @returns {Promise<TermsOfService | null>}
    */
  async getTosToAccept(token) {
    const res = await this._http.get(this._endpointToAccept, {
      headers: { Authorization: `Bearer ${token}` }
    });

    switch (res.status) {
      case 200:
        return res.data;
      default:
        return null;
    }
  }

  /**
   * @param {string} id - id of terms of service to accept
   * @param {string} token - authorization token
   * @returns {Promise<void>}
   */
  async acceptTos(id, token) {
    const res = await this._http.post(this._endpointAccept(id), undefined, {
      headers: { Authorization: `Bearer ${token}` }
    });

    switch (res.status) {
      case 200:
      case 204:
        return undefined;
      default:
        throw new NetworkError(res.status, res.data);
    }
  }
}
