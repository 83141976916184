import { useEffect } from "react";
import queryString from "query-string";
import { actions } from "../../store/reducers/app";
import config from "../../api/config";
import { LoadingScreen } from "../../new-lib/LoadingScreen/LoadingScreen";
import { paths } from "../../utils/urlPaths";
import { FixMeLater } from "src/new-lib/_types";
import { connect } from "react-redux";

 type Props = {
    dispatch: FixMeLater;
    history: FixMeLater;
  };
  
export const VerifyCode =({dispatch,history} :Props)=>{
    useEffect(()=>{
      const urlQueryParams = queryString.parse(history.location.search);
      dispatch(actions.saveCode(urlQueryParams.code));
      dispatch(actions.saveRedirectURI(`${config.redirectBase}/vericode`));
      dispatch(actions.verifyPhoneCode(urlQueryParams.code))
      .then((data) => {
          history.push({
          pathname: paths["home"],
          });
      });

    },[]);
    return <LoadingScreen />;
}

//export default VerifyCode;
export const VerifyCodePage = connect((state: FixMeLater) => {
  return {
    app: state.app,
  };
})(VerifyCode);
