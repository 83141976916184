

import { RootState } from "src/store/Model"
import { CmsApi } from "src/api/services/ContentAPI"
import { AxiosError } from "axios"
import { ContentDispatcher } from "./reducer"

export const actions = {
    fetchFaq: () =>
        async (dispatch: ContentDispatcher,
            getState: () => RootState,
            api: { services: { content: CmsApi } }) => {

            const content = getState().content.faq
            if (content.data || content.isLoading)
                return

            dispatch({ type: "fetch faq content" })
            const payload = await api.services.content.getFaqPageContent()

            if (isError(payload))
                dispatch({ type: "fetch flow onboarding start content failed", payload })
            else
                dispatch({ type: "fetch faq content succeeded", payload })
        },
    fetchHome: () =>
        async (dispatch: ContentDispatcher,
            getState: () => RootState,
            api: { services: { content: CmsApi } }) => {
            const homeContent = getState().content.home
            if (homeContent.isLoading || homeContent.data)
                return

            dispatch({ type: "fetch home content" })
            const payload = await api.services.content.getHomePageContent()


            if (isError(payload))
                dispatch({ type: "fetch home content failed", payload })
            else
                dispatch({ type: "fetch home content succeeded", payload })

        },
    fetchFlowOnboardingStart: () =>
        async (dispatch: ContentDispatcher, getState: () => RootState,
            api: { services: { content: CmsApi } }) => {
            const content = getState().content.flowOnboardingStart

            if (content.isLoading || content.data)
                return

            dispatch({ type: "fetch flow onboarding start content" })

            const payload = await api.services.content.getFlowOnboardingStartPageContent()


            if (isError(payload))
                dispatch({ type: "fetch flow onboarding start content failed", payload })
            else
                dispatch({ type: "fetch flow onboarding start content succeeded", payload })
        },
    fetchTopbar: () => async (dispatch: ContentDispatcher, getState: () => RootState,
        api: { services: { content: CmsApi } }) => {
            
            const content = getState().content.topbar

            if (content.isLoading || content.data) return

            dispatch({ type: "fetch topbar content" })

            const payload = await api.services.content.getTopbarContent()
            

            if (isError(payload))
                dispatch({ type: "fetch topbar content failed", payload })
            else
                dispatch({ type: "fetch topbar content succeeded", payload })
    }
}


const isError = (value: any | AxiosError): value is AxiosError => value && value.isAxiosError === true