import NetworkError from "../NetworkError";
import config from "../config";
import store from "../../store/configureStore"

export class PhoneValidationAPI {
  constructor(http) {
    this._http = http;
    this._endpoint = `${config.base}/api/v2/account/validatephone/`;
  }

  async getPhoneNumberValidationStatus(token) {
    const smsValidationFeatureIsEnabled = store.getState().featureManager.smsValidation;

    if (!smsValidationFeatureIsEnabled)
      return true;

    const res = await this._http.get(`${this._endpoint}status`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200) {
      return true;
    } else if (res.status === 404) {
      return false;
    } else {
      throw new NetworkError(res.status, res.data);
    }

  }

  async sendValidationSms(token) {
    const res = await this._http.get(this._endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200 || res.status === 204) {
      return true;
    }

    return false;
  }

  async verifySmsCode(token, code) {
    const smsValidationFeatureIsEnabled = store.getState().featureManager.smsValidation;

    if (!smsValidationFeatureIsEnabled)
      return false;

      const res = await this._http.get(`${this._endpoint}vericode/${code}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (res.status === 200 || res.status === 204) {
        return true;
      } else if (res.status === 422) {
        return false;
      } else {
        throw new NetworkError(res.status, res.data);
      }
  }
}
