import {
  FETCH_LATEST_TOS, 
  RECEIVE_LATEST_TOS,
  FETCH_TOS_TO_ACCEPT,
  RECEIVE_TOS_TO_ACCEPT,
  ACCEPTING_TOS_TO_ACCEPT,
  ACCEPTED_TOS_TO_ACCEPT,
  ACCEPT_TOS_TO_ACCEPT_FAILED
} from "./action-types"

export const actions = {
  fetchLatestTermsOfService() {
    return async (dispatch, getState, api) => {
      const { termsOfServices } = getState();

      const state = termsOfServices.latestTermsOfService;

      if (state !== "uninitialized" && state !== "error") {
        // Latest ToS has already been -or is being- fetched, use cached version.
        // Also refetch on error
        return;
      }

      dispatch({ type: FETCH_LATEST_TOS });

      try {
        const termsOfService = await api.services.termsOfServices.getLatestTos();

        dispatch({
          type: RECEIVE_LATEST_TOS,
          payload: termsOfService || "none"
        });

      } catch (e) {
        console.error("Failed to fetch latest terms of service", e);
        dispatch({
          type: RECEIVE_LATEST_TOS,
          payload: "error"
        });
      }
    };
  },

  fetchTermsOfServiceToAccept() {
    return async (dispatch, getState, api) => {
      const { app, termsOfServices } = getState();

      const state = termsOfServices.termsOfServiceToAccept;

      if (state !== "uninitialized" && state !== "error") {
        // ToS has already been -or is being- fetched, use cached version.
        // Also refetch on error
        return;
      }

      dispatch({ type: FETCH_TOS_TO_ACCEPT });

      try {
        const termsOfService = await api.services.termsOfServices.getTosToAccept(app.sessionToken);

        dispatch({
          type: RECEIVE_TOS_TO_ACCEPT,
          payload: termsOfService || "none"
        });

      } catch (e) {
        console.error("Failed to fetch terms of service to accept", e);
        dispatch({
          type: RECEIVE_TOS_TO_ACCEPT,
          payload: "error"
        });
      }
    };
  },

  acceptTermsOfServiceToAccept() {
    return async (dispatch, getState, api) => {
      const { app, termsOfServices } = getState();

      const tosToAccept = termsOfServices.termsOfServiceToAccept;
      const tosAcceptance = termsOfServices.tosAcceptance;

      if (tosAcceptance === "accepted") {
        // ToS has already been accepted
        return;
      }

      const id = tosToAccept.id;

      if (!id) {
        console.log("Trying to accept terms of service that doesn't exist")
        dispatch({ type: ACCEPT_TOS_TO_ACCEPT_FAILED });
        return;
      }

      dispatch({ type: ACCEPTING_TOS_TO_ACCEPT });

      try {
        await api.services.termsOfServices.acceptTos(id, app.sessionToken);

        dispatch({ type: ACCEPTED_TOS_TO_ACCEPT });

      } catch (e) {
        dispatch({ type: ACCEPT_TOS_TO_ACCEPT_FAILED });
        console.error("Failed to fetch latest terms of service", e);
      }
    };
  }
};