import { isValidPhoneNumber } from 'react-phone-number-input/mobile'

export const isValidMobilePhoneNumber = {
  rule: (val: string) => {
    return isValidPhoneNumber(val);
  },
  errorMessage: "Detta fält är obligatoriskt"
};


export {
  useDeviceSize,
  useDisableScroll
} from './hooks'

export {
  marginToCssProp,
  paddingToCssProp,
  rem,
  isWebUrl,
  isPhoneNumber,
  validatePhoneNumber,
  validateMobileNumber,
  cleanPhoneNumberString,
  validateZipCode,
  validateEmail,
  getCareUnitIconName,
  capitalizeWords,
  getNumOfChildren,
  getButtonIconColors,
} from './functions'
