
/*
export const REGISTRATION: LinkMap = {
  ROOT_PATH: '/registration',
  TERMS_OF_SERVICE_PATH: '/registration/terms-of-service',
  READ_TOS_PATH: '/registration/read-tos',
  MY_CONSENTS_PATH: '/registration/consents',
  MY_DETAILS_PATH: '/registration/my-details',
  WELCOME_PATH: '/registration/welcome'
}

export const LISTING: LinkMap = {
  CENTER_PATH: '/listing/center',
  DETAILS_PATH: '/listing/details',
  CHILD_DETAILS_PATH: '/listing/childdetails',
  CONFIRM_DETAILS_PATH: '/listing/confirm',
  RECEIPT_PATH: '/listing/receipt'
}

export const SWITCHBOARD: LinkMap = {
  ROOT_PATH: '/sboard',
  CENTER_PATH: '/sboard/centerchoice',
  DEFAULT_PATH: '/sboard/defaultchoice',
  EXTERNAL_CHAT_PATH: '/sboard/externalchat',
  NOT_INTEGRATED_PATH: '/sboard/externalchoice',
  NO_CHAT_PATH: '/sboard/externalnochat',
  HCU_SPECIFIC_PATH: '/sboard/hcuchoice',
  HCU_MISMATCH_PATH: '/sboard/hcuotherchoice',
  REGION_CHOICE: '/sboard/regionchoice',
  CENTER_SELECT: '/sboard/centerselect'
}

export const FASTLISTING: LinkMap = {
  ROOT_PATH: '/fastlisting',
  FAST_CENTER_PATH: '/fastlisting/centerchoice',
  DETAILS_PATH: '/fastlisting/details',
  RECEIPT_PATH: '/fastlisting/receipt'
}

export const HOME: LinkMap = {
  ROOT_PATH: '/home'
}

export const LOGIN: LinkMap = {
  ROOT_PATH: '/login'
}

export const LOGOUT: LinkMap = {
  ROOT_PATH: '/signout'
}

export const LOGOUT_CONFIRMED: LinkMap = {
  ROOT_PATH: '/logout'
}

export const SETTINGS: LinkMap = {
  ROOT_PATH: '/settings',
  EDIT_CONTACT_INFO_PATH: '/settings/contactinfo'
  MY_CARE_SELECTIONS_PATH: '/settings/mycareselections',
  READ_TOS_PATH: '/settings/read-tos'
}

export const CAPIOGO: LinkMap = {
  ROOT_PATH: '/capiogo',
  ROOT_PATH_DIRECT: '/capiogonow',
  MIDDLE_PATH: '/capiogomiddle'
}

export const DELETE_USER: LinkMap = {
  ROOT_PATH: '/delete/user'
}

export const CALLBACK: LinkMap = {
  ROOT_PATH: '/callback'
}

export const SIGN_CALLBACK: LinkMap = {
  ROOT_PATH: '/sign/callback'
}

export const FAQ: LinkMap = {
  ROOT_PATH: '/faq'
}

export const ERROR: LinkMap = {
  ROOT_PATH: '/error'
}

export const NEWSLETTER: LinkMap = {
  UNSUBSCRIBE_PATH: '/unsubscribe/:type?/:token?'
}

interface RouteMap {
  HOME: To
  FLOW_REDIRECT_LANDING: To
  LOGIN: To
  LOGOUT: To
  LOGOUT_CONFIRMED: To
  CANCEL_ACCOUNT: To
  SETTINGS: LinkMap
  LISTING: LinkMap
  FAST_LISTING: LinkMap
  FAQ: To
  SWITCHBOARD: LinkMap
  EXTERNAL: LinkMap
  REGISTRATION: LinkMap
  CARE_UNITS: LinkMap
}

*/
export const paths = {
  index: "/",
  login: "/login",
  "login > callback": "/sign/callback",

  logout: "/signout",
  "logout confirmation": "/logout",

  "registration > contact": '/registration/contact',
  "registration > ToS": '/registration/tos',

  home: "/home",

  "flow > capio go": "/capiogo",

  "unsubscribe (type, token)": "/unsubscribe/:type/:token",

  "my profile": "/settings/my-profile",

  "chat > settings": "/settings/chat",
  /**
   * TODO: Refactor - just send add an showListingModal query param when navigating to chat/modal 
   * instead of a new path
   */
  "chat > settings > modal": "/settings/chat/modal",
  "chat > onboarding > step 1": '/settings/chat/region',
  "chat > onboarding > step 2 (region)": '/settings/chat/region/:region',
  /** 
   * TODO: Refactor - extend /settings/chat with a path parameter like /settings/chat/:unitId
   */
  "chat > settings > selected": '/settings/chat/selected',
  
  "listing > select": "/listing/center",
  "listing > sign (hsaId)": "/listing/confirm/:hsaId",
  "listing > completed": "/listing/receipt",

  "fast listing > select": "/fastlisting/centerchoice",
  "fast listing > sign": "/fastlisting/details",
  "fast listing > sign (hsaId)": "/fastlisting/details/:hsaId",
  "fast listing > completed": "/fastlisting/receipt",

  "care units": "/care-units",

  "faq": "/faq",

  switchboard: '/sboard',
  "switchboard > select unit (1)": '/sboard/centerchoice',
  "switchboard > pre-selected unit": '/sboard/defaultchoice',
  "switchboard > external chat": '/sboard/externalchat',
  "switchboard > non-integrated unit (obsolete?)": '/sboard/externalchoice',
  "switchboard > unit without chat": '/sboard/externalnochat',
  "switchboard > specified unit": '/sboard/hcuchoice',
  "switchboard > other choice?": '/sboard/hcuotherchoice',
  "switchboard > select region": '/sboard/regionchoice',
  "switchboard > select unit (2)": '/sboard/centerselect',


  "1177": 'https://e-tjanster.1177.se/',
  instagram: 'https://www.instagram.com/capiosverige/',
  facebook: 'https://www.facebook.com/capiosverige/',
  linkedin: 'https://www.linkedin.com/company/capio-sverige/',
  "allergy info": 'https://capio.se/tips-och-halsorad/allergier-virus/',
  "how we work with QA": 'https://capio.se/om-capio/sa-arbetar-capio-med-kvalitet/',
  "accessability account": 'https://capio.se/tillganglighetsredogorelse/',
  cookies: 'https://capio.se/cookies/',

  callback: '/callback',

  error: '/error',

  "cookiebot proxy": "/cookiebot-proxy",


} as const


type PathMap = typeof paths
export type PathName = keyof PathMap
export type UrlPath = PathMap[PathName]
export type PathNameWithParameters = { name: PathName, params: Array<string> }

export type LocalPath =
  PathName
  | PathNameWithParameters


const isPathNameWithParameters = (subject: LocalPath | string): subject is PathNameWithParameters =>
  (subject as PathNameWithParameters)?.params !== undefined
const isPathName = (subject: LocalPath | string): subject is PathName =>
  typeof subject === "string" && paths[subject] !== undefined

export const subjectIsPathName = (subject: LocalPath | string): subject is LocalPath =>
  isPathName(subject) || isPathNameWithParameters(subject)


const extractPathParams = (str: string): string[] => {
  const regex = /\/:[^\/?]+(?=\/|\?|$)/g;
  const matches = str.match(regex);
  if (!matches) return [];
  return matches.map((match) => match.replace(/\/$/, ""));
};


export const createPath = (args: LocalPath | string) => {
  if (subjectIsPathName(args)) {
    if (isPathNameWithParameters(args)) {
      const pathValue = paths[args.name]
      const pathParams = extractPathParams(pathValue)

      return args.params.reduce((acc, curr, index) => acc.replace(pathParams[index], `/${curr}`), pathValue)
    }
    return paths[args]
  }
  return args;

}

/*
export const ROUTE_NAME: RouteMap = {
  HOME: HOME.ROOT_PATH,
  FLOW_REDIRECT_LANDING: CAPIOGO.ROOT_PATH,
  LOGIN: LOGIN.ROOT_PATH,
  LOGOUT: LOGOUT.ROOT_PATH,
  LOGOUT_CONFIRMED: LOGOUT_CONFIRMED.ROOT_PATH,
  CANCEL_ACCOUNT: DELETE_USER.ROOT_PATH,
  SETTINGS: {
    ROOT: SETTINGS.ROOT_PATH,
    CONSENT: SETTINGS.MY_CONSENTS_PATH,
    CONTACT: SETTINGS.EDIT_CONTACT_INFO_PATH,
    CHAT: '/settings/chat',
    TERMS_AND_DATA: '/settings/terms-and-data',
    ONBOARDING: '/settings/chat/onboarding',
    ONBOARDING_REGION: '/settings/chat/onboarding/region',
    ONBOARDING_UNIT: '/settings/chat/onboarding/units',
    SELECTED_UNIT: '/settings/chat/selected',
    SELECTED_MODAL: '/settings/chat/modal'
  },
  LISTING: {
    ROOT: LISTING.ROOT_PATH,
    CENTER: LISTING.CENTER_PATH,
    DETAILS: LISTING.DETAILS_PATH,
    CONFIRM: LISTING.CONFIRM_DETAILS_PATH,
    RECEIPT: LISTING.RECEIPT_PATH
  },
  FAST_LISTING: {
    CONFIRM: FASTLISTING.DETAILS_PATH,
    CENTER: FASTLISTING.FAST_CENTER_PATH,
    RECEIPT: FASTLISTING.RECEIPT_PATH
  },
  FAQ: FAQ.ROOT_PATH,
  SWITCHBOARD,
  EXTERNAL: {
    _1177: 'https://e-tjanster.1177.se/',
    INSTAGRAM: 'https://www.instagram.com/capiosverige/',
    FACEBOOK: 'https://www.facebook.com/capiosverige/',
    LINKEDIN: 'https://www.linkedin.com/company/capio-sverige/',
    ALLERGY_INFO: 'https://capio.se/tips-och-halsorad/allergier-virus/',
    HOW_WE_WORK_WITH_QA:
      'https://capio.se/om-capio/sa-arbetar-capio-med-kvalitet/',
    ACCESSABILITY_ACCOUNT: 'https://capio.se/tillganglighetsredogorelse/',
    COOKIES: 'https://capio.se/cookies/'
  },
  REGISTRATION: {
    CONTACT: '/registration/contact',
    ACCEPT_TOS: '/registration/tos'
  },
  CARE_UNITS: {
    ROOT: '/care-units'
  }
}
*/