import React from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import { actions } from "../../store/reducers/consents";
import { actions as appActions } from "../../store/reducers/app";
import { actions as termsOfServicesActions } from "src/store/reducers/terms-of-services";
import { ProfileView } from "./ProfileView";
import {
  isValidMobilePhoneNumber,
  isEmailAddress,
} from "../../utils/input-validation";
import { withRouter } from "react-router-dom";
import { formatPhoneNumber } from "react-phone-number-input";
import { tryExtractCountryCodeFromPhoneString } from "../../utils/functions";
import { paths } from "src/utils/urlPaths";
import { capitalizeWords } from "src/new-lib/_utils";

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      isLoading: false,
      showHasSavedText: false,
      mobileNumber: {
        value: this.props.myInformation.fullMobileNumber,
        errorMessage: "",
      },
      emailAddress: {
        value: this.props.myInformation.emailAddress,
        errorMessage: "",
      },
      streetAddress: {
        value: this.props.myInformation.streetAddress,
        errorMessage: "",
      },
      zipCode: {
        value: this.props.myInformation.zipCode,
        errorMessage: "",
      },
      postAddress: {
        value: this.props.myInformation.postAddress,
        errorMessage: "",
      },
      displayFormError: false,
      isPhoneNumberValidated: this.props.isPhoneNumberValidated,
      openSmsSentPopUp: false,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.props
      .dispatch(appActions.fetchContactInfo())
      .then((data) => {
        this.setState({
          mobileNumber: { value: data.fullMobileNumber, errorMessage: "" },
        });
        return this.props.dispatch(actions.getAllConsentTypes());
      })
      .then((allConsents) => {
        return this.props
          .dispatch(actions.getAllUserConsentTypes())
          .then((userConsents) => {
            this.props.dispatch(
              actions.syncAcceptedConsents({
                all: allConsents,
                user: userConsents,
              })
            );
          });
      })
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });

    this.props.dispatchGetTos();

    this.props
      .dispatch(appActions.getPhoneValidationStatusInfo())
      .then((data) => {
        this.setState({
          isPhoneNumberValidated: data,
        });
      });
  }

  setMobileNumber = (ev) => {
    const value = ev.target ? ev.target.value : "";
    this.setState((state) => {
      const { rule, errorMessage } = isValidMobilePhoneNumber;

      const countryEncodedValue =
        value && tryExtractCountryCodeFromPhoneString(value)
          ? value
          : `+46${value}`;

      const mobileNumber = rule(countryEncodedValue)
        ? { value, errorMessage: "" }
        : { value, errorMessage };

      return {
        mobileNumber,
        displayFormError: false,
      };
    });
  };

  setEmail = (ev) => {
    const value = ev.target ? ev.target.value : "";

    this.setState(() => {
      return isEmailAddress.rule(value)
        ? { displayFormError: false, emailAddress: { value, errorMessage: "" } }
        : {
            displayFormError: false,
            emailAddress: { value, errorMessage: isEmailAddress.errorMessage },
          };
    });
  };

  setStreetAddress = (ev) => {
    const value = ev.target ? ev.target.value : "";

    this.setState(() => {
      return {
        displayFormError: false,
        streetAddress: { value, errorMessage: "" },
      };
    });
  };

  setZipCode = (ev) => {
    const value = ev.target ? ev.target.value : "";

    if (value && value.length === 5) {
      this.setState({
        displayFormError: false,
        zipCode: { value, errorMessage: "" },
      });
    } else if (value && value.length !== 5) {
      this.setState({
        displayFormError: false,
        zipCode: { value, errorMessage: "Postnummer är ej giltigt." },
      });
    } else if (!value.trim()) {
      this.setState({
        displayFormError: false,
        zipCode: { value, errorMessage: "" },
      });
    }
  };

  setPostAddress = (ev) => {
    const value = ev.target ? ev.target.value : "";

    this.setState(() => {
      return {
        displayFormError: false,
        postAddress: { value, errorMessage: "" },
      };
    });
  };

  onSaveChangesButtonClick = () => {
    const mobileFieldError = this.state.mobileNumber.errorMessage;
    const emailFieldError = this.state.emailAddress.errorMessage;
    const zipCodeFieldError = this.state.zipCode.errorMessage;

    const mobileRequiredError = this.state.mobileNumber.value
      ? mobileFieldError
      : "Du måste ange ett mobilnummer.";

    const emailRequiredError = this.state.emailAddress.value
      ? emailFieldError
      : "Du måste ange en e-postadress.";

    const formIsValid =
      !mobileRequiredError &&
      !mobileFieldError &&
      !emailRequiredError &&
      !emailFieldError &&
      !zipCodeFieldError;

    const newContactFormState = {
      mobileNumber: {
        ...this.state.mobileNumber,
        errorMessage: mobileRequiredError,
      },
      emailAddress: {
        ...this.state.emailAddress,
        errorMessage: emailRequiredError,
      },
    };
    this.setState({ displayFormError: !formIsValid, ...newContactFormState });

    if (formIsValid) {
      const countryNumber = tryExtractCountryCodeFromPhoneString(
        this.state.mobileNumber.value
      );
      const phone = countryNumber
        ? this.state.mobileNumber.value
        : `+46${this.state.mobileNumber.value}`;

      const phoneFormatted = formatPhoneNumber(phone).replace(/-|\s/g, "");

      this.props.dispatch(
        actions.onMyInfoChangeAll({
          validatedMobileNumber: phoneFormatted,
          emailAddress: this.state.emailAddress.value,
          countryNumber: countryNumber || "+46",
          zipCode: this.state.zipCode.value,
          streetAddress: this.state.streetAddress.value,
          postAddress: this.state.postAddress.value,
        })
      );

      this.setState({ isLoading: true });
      this.props
        .dispatch(actions.updateContactInformation())
        .then(() => {
          this.props
            .dispatch(actions.saveAllUserConsentTypes())
            .then(() => {
              this.setState({ showHasSavedText: true, isLoading: false });
            })
            .catch(() => {});
        })
        .catch(() => {});
    }
  };

  getName = () => {
    if (
      this.props.myInformation.firstName &&
      this.props.myInformation.lastName &&
      this.props.myInformation.protectedIdentity === "N"
    ) {
      return capitalizeWords(
        `${this.props.myInformation.firstName} ${this.props.myInformation.lastName}`
      );
    } else return null;
  };

  render() {
    return (
      <>
        <ProfileView
          name={this.getName()}
          phoneField={{
            value: this.state.mobileNumber.value,
            errorText: this.state.mobileNumber.errorMessage,
            onChange: this.setMobileNumber,
            disable: this.state.isLoading,
          }}
          emailField={{
            value: this.state.emailAddress.value,
            onChange: this.setEmail,
            errorText: this.state.emailAddress.errorMessage,
            disable: this.state.isLoading,
          }}
          streetAddressField={{
            value: this.state.streetAddress.value,
            onChange: this.setStreetAddress,
            errorText: "",
            disable: this.state.isLoading,
          }}
          zipCodeField={{
            value: this.state.zipCode.value,
            onChange: this.setZipCode,
            errorText: this.state.zipCode.errorMessage,
            disable: this.state.isLoading,
          }}
          postAddressField={{
            value: this.state.postAddress.value,
            onChange: this.setPostAddress,
            errorText: "",
            disable: this.state.isLoading,
          }}
          consents={this.props.consentTypes.map((con) => ({
            key: con.consentTypeId,
            disable: this.state.isLoading,
            checked: con.isAccepted,
            title: con.name,
            paragraphs: [con.description],
            onChange: (event) =>
              this.props.dispatch(
                actions.toggleConsentTypeCheckbox(
                  con.consentTypeId,
                  event.target.checked
                )
              ),
          }))}
          onSaveChangesButtonClick={this.onSaveChangesButtonClick}
          showHasSavedText={this.state.showHasSavedText}
          resetHasSavedText={() => {
            this.setState({ showHasSavedText: false });
          }}
          onDeleteAccountClick={() => {
            this.setState({ isLoading: true });
            this.props.dispatchCancelAccount();
          }}
          termsOfService={this.props.termsOfService}
          loading={this.state.isLoading}
          isPhoneNumberValidated={this.state.isPhoneNumberValidated}
          onSendPhoneNumberValidationLink={() => {
            this.setState({ isLoading: true });
            this.props.dispatchSendPhoneNumberValidationLink();
            this.setState({ isLoading: false });
            this.setState({ openSmsSentPopUp: true });
          }}
          openSmsSentPopUp={this.state.openSmsSentPopUp}
          handleClosePhoneValidationPopUp={() => {
            this.setState({ openSmsSentPopUp: false });
          }}
        />
      </>
    );
  }
}

Page.propTypes = {
  onBackPath: PropTypes.string,
  consentTypes: PropTypes.array,
};

const mapStateToProps = (state) => ({
  consentTypes: state.consent.consentTypes,
  myInformation: state.consent.myInformation,
  termsOfService: state.termsOfServices.latestTermsOfService,
});

const mapDispatchToProps = (dispatch, { history }) => ({
  dispatchCancelAccount: () =>
    dispatch(appActions.deleteUserInfo()).then(() => {
      setTimeout(() => {
        history.replace(paths["logout"]);
      }, 1000);
    }),
  dispatchGetTos: () =>
    dispatch(termsOfServicesActions.fetchLatestTermsOfService()),
  dispatch,
  dispatchSendPhoneNumberValidationLink: () =>
    dispatch(appActions.sendPhoneValidationLink()),
});

export const ProfileEditContactInfoPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Page)
);
