import React from 'react'
import Markdown from 'markdown-to-jsx'
import Loader from 'react-loader-spinner'
import scss from '../_styles/index.module.scss'
import { Typography } from '../Typography'
import styles from './TermsOfServices.module.scss'
import { FixMeLater } from '../_types'

type Props = {
  termsOfService: FixMeLater,
  overrides: FixMeLater
}

export const TermsOfServices = ({
  termsOfService,
  overrides = {
    h2: { component: Typography, props: { tag: 'h2', variant: 'h3' } },
    h3: { component: Typography, props: { tag: 'p', weight: 900 } },
    p: { component: Typography, props: { tag: 'p' } },
    ul: { props: { className: styles.ul + ' ' + Typography } },
    li: { props: { className: styles.li } }
  }
}: Props) => {
  const isLoading =
    termsOfService === 'loading' || termsOfService === 'uninitialized'
  const isError = termsOfService === 'error' || termsOfService === 'none'

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader type='Oval' color={scss.primaryNavy} width={80} height={80} />
      </div>
    )
  }

  const content = termsOfService ? termsOfService.content : undefined

  if (!content || isError) {
    return <Typography tag='p'>{failedLoading}</Typography>
  }

  return (
    <article className={styles['terms-of-conditions']}>
      <Markdown options={{ overrides }}>
        {termsOfService.content || ''}
      </Markdown>
    </article>
  )
}

const failedLoading =
  'Vi kan inte ladda innehållet för tillfället. Vänligen försök igen senare'
