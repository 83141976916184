import React from "react";
import { bool, func, node, oneOfType, string } from "prop-types";
import styles from "./Checkbox.module.scss";
import UncheckedCircle from "@material-ui/icons/RadioButtonUnchecked";
import CheckRounded from "@material-ui/icons/CheckRounded";
import MUICheckbox from "@material-ui/core/Checkbox";
import MUIFormControlLabel from '@material-ui/core/FormControlLabel';


const CheckboxInput = ({ defaultChecked, disabled, onChange, onFocus, onBlur, e2e }) => {
    const iconClass = { root: disabled ? styles.icon : styles.icon };
    const iconCheckedClass = { root: disabled ? styles.iconDisabled : styles.iconChecked };
    const iconContainerClass = disabled ? styles.iconContainerDisabled : styles.iconContainer;
    const iconCheckedContainerClass = disabled ? styles.iconCheckedContainerDisabled : styles.iconCheckedContainer;
    return (
        <MUICheckbox
            defaultChecked={defaultChecked}
            disabled={disabled}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            color={"primary"}
            icon={<span className={iconContainerClass}><UncheckedCircle classes={iconClass} fontSize={"large"} /></span>}
            checkedIcon={<span className={iconCheckedContainerClass}><CheckRounded classes={iconCheckedClass} fontSize={"inherit"} /></span>}
            size="small"
            inputProps={{ "data-e2e": e2e }}
        />);
}


const CheckboxField = ({ defaultChecked, disabled, onChange, onFocus, onBlur, label, e2e }) => {
    return (
        <MUIFormControlLabel
            control={
                <CheckboxInput
                    onChange={onChange}
                    onFocus={onFocus}
                    onFocus={onBlur}
                    disabled={disabled}
                    defaultChecked={defaultChecked}
                    e2e={e2e}
                />}
            label={label}
            classes={{ label: styles.label }}
        />
    );
}

CheckboxField.propTypes = {
    defaultChecked: bool,
    disabled: bool,
    label: oneOfType([node, string]),
    onChange: func,
    onFocus: func,
    onBlur: func,
    e2e: string,
}

export { CheckboxField as Checkbox };