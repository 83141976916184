import { initialState } from "./contentState"
import { AxiosError } from "axios"
import { FAQContent, FlowOnboardingStartContent, HomeContent, TopbarContent } from "src/api/services/ContentAPIModel.js"


type FAQActionArgs =
    { type: "fetch faq content" }
    | { type: "fetch faq content succeeded", payload: FAQContent }
    | { type: "fetch faq content failed", payload: AxiosError }

type HomeActionArgs =
    { type: "fetch home content" }
    | { type: "fetch home content succeeded", payload: HomeContent }
    | { type: "fetch home content failed", payload: AxiosError }

type FlowOnboardingStartActionArgs =
    { type: "fetch flow onboarding start content" }
    | { type: "fetch flow onboarding start content succeeded", payload: FlowOnboardingStartContent }
    | { type: "fetch flow onboarding start content failed", payload: AxiosError }

type TopbarActionArgs = { type: "fetch topbar content" } 
| { type: "fetch topbar content succeeded", payload: TopbarContent }
| { type: "fetch topbar content failed", payload: AxiosError }


type ContentActionArgs = FAQActionArgs | HomeActionArgs | FlowOnboardingStartActionArgs | TopbarActionArgs


export type ContentDispatcher = (args: ContentActionArgs) => void

export const reducer = (state = initialState, action: ContentActionArgs) => {
    switch (action.type) {
        case "fetch faq content":
            return { ...state, faq: { isLoading: true } }
        case "fetch faq content succeeded":
            return { ...state, faq: { data: action.payload, loading: false } }
        case "fetch faq content failed":
            return { ...state, error: action.payload, loading: false }


        case "fetch home content":
            return { ...state, home: { ...state.home, isLoading: true } }
        case "fetch home content succeeded":
            return { ...state, home: { data: action.payload, isLoading: false } }
        case "fetch home content failed":
            return { ...state, home: { error: action.payload, isLoading: false } }


        case "fetch flow onboarding start content":
            return { ...state, flowOnboardingStart: { isLoading: true } }
        case "fetch flow onboarding start content succeeded":
            return { ...state, flowOnboardingStart: { data: action.payload, isLoading: false } }
        case "fetch flow onboarding start content failed":
            return { ...state, flowOnboardingStart: { error: action.payload, isLoading: false } }

        
        case "fetch topbar content": 
            return { ...state, topbar: { isLoading: true }}
        case "fetch topbar content succeeded": 
            return { ...state, topbar: { data: action.payload, isLoading: false }}
        case "fetch topbar content failed":
            return { ...state, topbar: { error: action.payload, isLoading: false }}


        default:
            return state
    }
}

