export const initialState = {
  myInformation: {
    name: '',
    mobileNumber: '',
    validatedMobileNumber: '',
    mobileNumberVerification: '',
    countryNumber: '+46',
    emailAddress: '',
    streetAddress: '',
    postAddress: '',
    zipCode: '',
    protectedIdentity: ''
  },
  sparLoading: false,
  sparInformation: {
    streetAddress: '',
    postAddress: '',
    zipCode: '',
    privacyMark: ''
  },
  child: {
    name: '',
    personalNumber: '',
    streetAddress: '',
    postAddress: '',
    zipCode: ''
  },
  consents: {
    serviceConditions: false,
    consentToSMSAndEmailReminder: false,
    consentToSMSAndEmailNewsLetter: false,
    listChildConfirmation: false
  },
  consentTypes: [],
  didUpdateContactInformation: false,
  didAcceptServiceConditions: false,
  loading: false,
  selectedCenter: {
    label: '',
    value: '',
    region: ''
  },
  switchBoard: {
    selectedRegion: '',
    selectedHealthCareUnitId: ''
  },
  wasRoutedBySettings: false
};


