import {
  FETCH_LATEST_TOS,
  RECEIVE_LATEST_TOS,
  FETCH_TOS_TO_ACCEPT,
  RECEIVE_TOS_TO_ACCEPT,
  ACCEPTING_TOS_TO_ACCEPT,
  ACCEPTED_TOS_TO_ACCEPT,
  ACCEPT_TOS_TO_ACCEPT_FAILED
} from "./action-types"

import { initialState } from "./initialState"

/** 
 * @typedef {import("./initialState").TermsOfServiceState} TermsOfServiceState
 */

/**
 * @param {TermsOfServiceState} state 
 * @param action 
 * @returns {TermsOfServiceState}
 */
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LATEST_TOS:
      return { ...state, latestTermsOfService: "loading" };

    case RECEIVE_LATEST_TOS:
      return { ...state, latestTermsOfService: action.payload };

    case FETCH_TOS_TO_ACCEPT:
      return { ...state, termsOfServiceToAccept: "loading" };
    
    case RECEIVE_TOS_TO_ACCEPT:
      return { ...state, termsOfServiceToAccept: action.payload };

    case ACCEPTING_TOS_TO_ACCEPT:
      return { ...state, tosAcceptance: "loading" };

    case ACCEPTED_TOS_TO_ACCEPT:
      return { ...state, tosAcceptance: "accepted" };

    case ACCEPT_TOS_TO_ACCEPT_FAILED:
      return { ...state, tosAcceptance: "error" };

    default:
      return state;
  }
};
