import React, { useState } from 'react'
import styles from './IconButton.module.scss'
import classNames from 'classnames'
import { marginToCssProp, isWebUrl } from '../_utils'
import { getButtonIconColors } from 'src/new-lib/_utils'
import { Link } from 'react-router-dom'
import { Icon, IconNames, IconSizes } from '../Icon'
import { FixMeLater } from '../_types'
import { To, Targets } from '../LinkTo'
import { createPath } from 'src/utils/urlPaths'

/**
* @description IconButton
*/

export type ButtonTypes = "button" | "submit" | "reset"
export type IconButtonVariants = "primary" | "secondary" | "danger"

interface Props {
  variant?: IconButtonVariants
  onClick?: React.MouseEventHandler
  onMouseUp?: React.MouseEventHandler
  small?: boolean
  disabled?: boolean
  to?: To
  targetTo?: Targets,
  expand?: boolean
  margin?: FixMeLater
  type?: ButtonTypes
  form?: string
  icon?: IconNames
  iconSize?: IconSizes
}

export const IconButton = ({
  variant = 'primary',
  onClick,
  onMouseUp,
  small,
  disabled,
  to,
  targetTo = '_self',
  expand,
  margin,
  type,
  form,
  icon = 'close',
  iconSize = 'lg',
}: Props) => {
  const iconButtonClassNames = classNames({
    [styles.primary]: variant === 'primary',
    [styles['primary-disabled']]: variant === 'primary' && disabled,

    [styles.secondary]: variant.includes('secondary'),
    [styles['secondary-disabled']]: variant.includes('secondary') && disabled,

    [styles.danger]: variant === 'danger',
    [styles['danger-disabled']]: variant === 'danger' && disabled,

    [styles['small-base']]: small,
    [styles.expand]: expand,
    [styles['link-button-base']]: to
  })

  const [defaultIconColor, hoverIconColor, disabledIconColor] = getButtonIconColors(variant)

  const [iconColor, setIconColor] = useState(disabled ? disabledIconColor : defaultIconColor)

  const handleMouseEnter = () => setIconColor(hoverIconColor)
  const handleMouseLeave = () => setIconColor(defaultIconColor)
  
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    if (onClick) {
      ev.preventDefault()
      onClick(ev)
    }
  }
  
  const handleMouseUp: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    if (onMouseUp) {
      ev.preventDefault()
      onMouseUp(ev)
    }
  }
  
  const ButtonContent = () => <Icon name={icon} size={iconSize} color={iconColor} />

  const Button = () => {
    return (
      <button
        className={iconButtonClassNames}
        onClick={handleClick}
        onMouseUp={handleMouseUp}
        style={{ margin: marginToCssProp(margin) }}
        type={type}
        form={form}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ButtonContent />
      </button>
    )
  }

  const LinkButton = () => {
    const toPath = createPath(to)
    return (
      isWebUrl(toPath)
      ? <a
          className={iconButtonClassNames}
          href={toPath}
          target={targetTo}
          rel='noreferrer'
          style={{
            margin: marginToCssProp(margin),
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ButtonContent />
        </a>
      : <Link
          className={iconButtonClassNames}
          to={to}
          target={targetTo}
          rel='noreferrer'
          style={{ margin: marginToCssProp(margin) }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ButtonContent />
        </Link>
    )
  }

  return to ? <LinkButton /> : <Button />
}
