import config from '../config';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { FAQContent, FAQResponse, FlowOnboardingStartContent, FlowOnboardingStartResponse, HomeContent, HomeResponse, TopbarContent, TopbarResponse } from './ContentAPIModel';
import { trackAxiosError } from 'src/new-lib/_utils/analytics';
import { isAxiosError } from 'src/new-lib/_utils/functions';



export class CmsApi {
    private _http: AxiosInstance;
    private _endpoint: string;

    constructor(http: AxiosInstance) {
        this._http = http;
        this._endpoint = `${config.cmsBaseUri}`;
    }

    async getFaqPageContent(): Promise<FAQContent | AxiosError> {
        try {
            const res: AxiosResponse<FAQResponse> = await this._http.get(`${this._endpoint}/web-faq?populate[sections][populate][qna]=*`)
            return res.data.data.attributes
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                trackAxiosError(error)
                return error
            }
            throw error
        }
    }

    async getHomePageContent(): Promise<HomeContent | AxiosError> {
        try {
            const res: AxiosResponse<HomeResponse> = await this._http.get(`${this._endpoint}/web-home?populate[sectionOne][populate][ctaButton]=*&populate[sectionTwo][populate][ctaButton]=*&populate[sectionThree][populate][ctaButton]=*&populate[sectionFour][populate][cardsCollection]=*`)
            return res.data.data.attributes

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                trackAxiosError(error)
                return error
            }
            throw error
        }
    }

    async getFlowOnboardingStartPageContent(): Promise<FlowOnboardingStartContent | AxiosError> {

        try {
            const res: AxiosResponse<FlowOnboardingStartResponse> = await this._http.get(`${this._endpoint}/web-flow-onboarding-start?populate=*`)
            return res.data.data.attributes

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                trackAxiosError(error)
                return error
            }
            throw error
        }
    }

    async getTopbarContent(): Promise<TopbarContent | AxiosError> {
        try {
            const res: AxiosResponse<TopbarResponse> = await this._http.get(`${this._endpoint}/web-topbar?populate=*`)
            return res.data.data.attributes

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                trackAxiosError(error)
                return error
            }
            throw error
        }
    }

}



export const createTryGetContentValueFn = <T extends { data?: Object, isLoading: boolean }>(content: T) => (selector: (c: T["data"]) => string | undefined) => {
    if (!content.data || content.isLoading)
        return ""
    return selector(content.data)
}