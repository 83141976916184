import NetworkError from "../NetworkError";
import config from "../config";
import { AxiosInstance } from "axios";

export class AccountAPI {
    private _http: AxiosInstance
    private _endpoint: string

    constructor(http: AxiosInstance) {
        this._http = http
        this._endpoint = `${config.base}/api/v2/account`
    }

    async deleteUserInfo(token: string) {
        const res = await this._http.delete(this._endpoint, {
            headers: { Authorization: `Bearer ${token}` }
        });
    
        if (res.status !== 200) {
            throw new NetworkError(res.status, res.statusText, res.data);
        }
    
        return res.data;
    }
}
