import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { paths } from "../../utils/urlPaths";
import { HomeView } from "./HomeView";
import { LoggedInLayout } from "../_layouts";
import { getDisplayName, getCareCentersSelector } from "../../store/selectors";
import queryString from "query-string";
import { actions as appActions } from "../../store/reducers/app";
import { actions as contentActions } from "../../store/reducers/content"

const Page = (props) => {
  
  useEffect(() => {
    props.dispatchFetchContent()
  }, [])

  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneNumberValidated, setIsPhoneNumberValidated] = useState("");
  

  const urlQueryParams = queryString.parse(props.location.search);
  const service = urlQueryParams.service || urlQueryParams.s;
  if (service === "listing") {
    props.history.push({
      pathname: paths["listing > select"],
      search: props.location.search,
    });
  }

  if (service === "capiogo" || service === "dhc") {
    props.history.push({
      pathname: paths["switchboard"],
      search: props.location.search,
    });
  }

  const { app, displayName, centers, savedCenterId, protectedId } = props;

  const getSavedCareCenterData = () => {
    if (!savedCenterId) return null;

    const careUnit = centers.find((i) => i.idExt === savedCenterId);
    return {
      careUnit: careUnit.healthcareunit,
      phoneNumber: careUnit.healthcareunitPhoneNumber,
      address: careUnit.healthcareunitAddress,
      postCode: careUnit.healthcareunitZipCode,
      city: careUnit.cityname,
      to: careUnit.healthcareunitWebAddress,
    };
  };
  if (isPhoneNumberValidated === "") {
    var someting = props.dispatchGetPhoneValidationStatus();
    someting.then(function(result) {
      setIsPhoneNumberValidated(result);
    });
  }

  return (
    <LoggedInLayout>
      {!app.userLoggedIn && <Redirect to={paths["logout"]} />}

      {app.userIsRegistered && !app.applicationGlobalLoading && (
        <HomeView
          content={props.content}
          displayName={displayName}
          savedCareCenterData={getSavedCareCenterData()}
          protectedId={protectedId}
          onSendPhoneNumberValidationLink={() => {
            setIsLoading(true);
            props.dispatchSendPhoneNumberValidationLink();
            setIsLoading(false);
          }}
          loading={isLoading}
          isPhoneNumberValidated={isPhoneNumberValidated}
        />
      )}
    </LoggedInLayout>
  );
};
const mapStateToProps = (state) => ({
  app: state.app,
  displayName: getDisplayName(state),
  centers: getCareCentersSelector(state),
  savedCenterId: state.consent.myInformation.primaryHealthCareEntityId,
  protectedId: state.consent.myInformation.protectedIdentity,
  content: state.content.home
});

const mapDispatchToProps = (dispatch, { history }) => ({
  dispatchSendPhoneNumberValidationLink: () =>
    dispatch(appActions.sendPhoneValidationLink()),

  dispatchGetPhoneValidationStatus: () =>
    dispatch(appActions.getPhoneValidationStatusInfo()).then((data) => {
      return data;
    }),
  
  dispatchFetchContent: () => {
    dispatch(contentActions.fetchHome())
    dispatch(contentActions.fetchFlowOnboardingStart())
  }
});

export const HomePage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Page)
);
