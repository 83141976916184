import collection from "../../assets/sl-icons/new-collection.svg";
import scss from "../_styles/index.module.scss";
import { rem, marginToCssProp } from "../_utils";
import { FixMeLater } from "src/new-lib/_types";

export type IconSizes = "sm" | "md" | "lg" | "xl" | "xxl" | string;

export type IconColors =
  | "primary"
  | "success"
  | "error"
  | "secondary"
  | "hover"
  | "disabled";

export type CareUnitIcons =
  | "hospital-house"
  | "stethoscope"
  | "psychiatric-care"
  | "dumbbell"
  | "gynecology"
  | "skin"
  | "surgeon"
  | "orthopedics"
  | "x-ray"
  | "urology"
  | "eye"
  | "ears-nose-throat"
  | "rheumatology"
  | "ultra-sound";

export type IconNames =
  | "check"
  | "question"
  | "close"
  | "singel-user-neutral"
  | "logout"
  | "share-link"
  | "completed"
  | "search"
  | "reschedule"
  | "info"
  | "happy-face"
  | "calendar"
  | "new-appointment"
  | "bed"
  | "completed-notepad"
  | "nav-arrow-up"
  | "nav-arrow-right"
  | "nav-arrow-down"
  | "nav-arrow-left"
  | "remove-minus"
  | "chevron-up"
  | "chevron-right"
  | "chevron-left"
  | "chevron-down"
  | "medical-phone-app"
  | "phone"
  | "mobile"
  | "arrow-border"
  | "bin"
  | "bank-id"
  | "bank-id-white"
  | "bank-id-black"
  | "nursing-center"
  | "mid-wife"
  | "on-call-clinic"
  | "corporate-care"
  | "youth-centre"
  | "inbox"
  | CareUnitIcons;

interface Props {
  name: IconNames;
  size?: IconSizes;
  color?: IconColors;
  margin?: FixMeLater;
}

const sizes = {
  sm: rem("12px"),
  md: rem("16px"),
  lg: rem("24px"),
  xl: rem("32px"),
  xxl: rem("72px"),
};

const colors = {
  primary: scss.primaryNavy,
  secondary: scss.primaryWhite,
  success: scss.success,
  error: scss.error,
  hover: scss.accentsHoverBlue,
  disabled: scss.disabled,
};

/**
 * @description Api for rendering various svg-icons
 *
 * To add additional icons add an svg as symbol to src/assets/sl-icons/new-collection,
 * assign it an id that can be referred to in the jsdoc typdef to support intellisense
 */

export const Icon = ({
  name,
  size = "md",
  color = "primary",
  margin = {},
}: Props) => {
  return (
    <svg
      style={{ margin: marginToCssProp(margin) }}
      height={sizes[size]}
      width={sizes[size]}
      stroke={colors[color]}
      fill={colors[color]}
    >
      <use xlinkHref={`${collection}#${name}`} />
    </svg>
  );
};
