import React, { useState } from 'react'
import styles from './SelectedCareUnitView.module.scss'
import PropTypes from 'prop-types'
import { Typography } from '../../new-lib/Typography'
import { Icon } from '../../new-lib/Icon'
import { TextField } from '../../new-lib/TextField'
import { LinkRow } from '../../new-lib/LinkRow'
import { Button } from '../../new-lib/Button'
import { useDeviceSize, getCareUnitIconName } from 'src/new-lib/_utils'


/**
 * @description Select Care-unit view
 */

type ICareUnit = {
  id: string; // change from `number` to `string`
  hsaid: string;
  name: string;
  street: string;
  postcode: string;
  postarea: string;
  phone: string;
  webAddress: string;
  careAreas: string[];
  type: string;
  region: string;
  onClick: () => void;
  idExt: string;
  isDigitalProvider: boolean;
  isDigitalProviderActive: boolean;
  isPatientListable: boolean;
  isbvc: boolean;
  videoCallDelegation: boolean;
}

type Props = {
  region: string;
  careUnits: ICareUnit[];
  onClickRegionSelect: () => void;
  onSaveHcu: (hcu: ICareUnit) => void;
}

const cardTitle = 'Förvald Vårdcentral'
const cardText =
  'Välj den vårdcentral du vill ska vara förvald vid digitala vårdbesök '

export const SelectedCareUnitView = ({
  region,
  careUnits,
  onClickRegionSelect,
  onSaveHcu
}: Props) => {
  const [filteredCareUnits, setFilteredCareUnits] = useState(careUnits)
  const { isMobile } = useDeviceSize()

  const handleSearchCareUnitChange = (ev: any) => {
    const _filteredCareUnits = []

    for (let i = 0; i < careUnits.length; i++) {
      const careUnit = careUnits[i]
      const careUnitDoesIncludeSearchValue = careUnit.name
        .toUpperCase()
        .includes(ev.target.value.toUpperCase())
      if (careUnitDoesIncludeSearchValue) _filteredCareUnits.push(careUnit)
    }
    ev.preventDefault()

    setFilteredCareUnits(_filteredCareUnits)
  }

  return (
    <div className={styles.body}>
      <section className={styles.card}>
        <div className={styles['back-link']} onClick={onClickRegionSelect}>
          <Icon name='nav-arrow-left' />
          <Typography tag='em' margin={{}}>
            Välj annan region
          </Typography>
        </div>

        <Typography tag='h2' margin={{ b: 16 }}>
          {cardTitle}
        </Typography>
        <Typography tag='p' display='inline' margin={{}}>
          {cardText}
        </Typography>

        <TextField
          labelId='1'
          title='Sök mottagning'
          labelText='Mottagningens namn'
          name='search-unit'
          onChange={handleSearchCareUnitChange}
          margin={{ t: isMobile ? 40 : 32 }}
        />

        <Typography tag='p' margin={{ t: isMobile ? 24 : 32 }}>
          Visar alla vårdcentraler i {region}
        </Typography>

        <div className={styles['link-rows-container']}>
          {filteredCareUnits.map((unit) => (
            <LinkRow
              key={unit.id}
              onClick={() => onSaveHcu(unit)}
              title={unit.name}
              leftIconName={getCareUnitIconName(unit.careAreas)}
              rightIconName='arrow-border'
              rightIconSize='md'
            >
              <Typography tag='p' margin={{}}>
                {unit.street}
              </Typography>
              <Typography tag='p' margin={{}}>
                {unit.postcode} {unit.postarea}
              </Typography>
            </LinkRow>
          ))}
        </div>
        <Button
          expand
          iconRight={isMobile ? 'close' : null}
          childMargin={{ r: 8, t: 2 }}
          margin={isMobile && { t: 24, b: 34 }}
          to="home"
          variant={isMobile ? 'secondary-light' : 'text'}
        >
          avbryt
        </Button>
      </section>
    </div>
  )
}

SelectedCareUnitView.propTypes = {
  region: PropTypes.string.isRequired,
  careUnits: PropTypes.array.isRequired,
  onClickRegionSelect: PropTypes.func.isRequired,
  onSaveHcu: PropTypes.func.isRequired
}
