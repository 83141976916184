import { Button } from "src/lib";
import { Typography } from "src/new-lib/Typography";
import styles from "./ProfileView.module.scss";

const PhoneValidationSection =(props)=>{
return (
    <section className={styles["validate-phone-number-card"]}>
        <Typography tag="h2" margin={{}}>
            Bekräfta telefonnummer
        </Typography>
        <Typography tag="p" margin={{ t: props.isMobile ? 16 : 24, b: 24 }}>
            <b>{props.line1}</b>
            <br />
            {props.line2}
        </Typography>
        <div className={styles.btn}>
            <Button
            expand
            variant="primary"
            onClick={props.clickEvent}
            >
            skicka länk
            </Button>
        </div>
        </section>
);
}

export default PhoneValidationSection;