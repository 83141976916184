export const initialState = {
  applicationGlobalLoading: false,
  userIsRegistered: false,
  userLoggedIn: false,
  sessionToken: '',
  idToken: '',
  identityInformation: {},
  sideDrawerToggled: false,
  code: '',
  signCode: '',
  redirectURI: '',
  listingPdfURI: '',
  errorOccured: false
};
